










import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import RevenueEventsForm from "@/settings/components/revenue-events/RevenueEventsForm.vue";
import RevenueEventRequestModel, {
  GetRevenueEventRequestModel,
} from "@/settings/models/revenue-events/RevenueEventRequestModel";
import RevenueEventModel from "@/settings/models/revenue-events/RevenueEventModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    RevenueEventsForm,
  },
})
export default class RevenueEventView extends mixins(UnsavedChangesMixin) {
  localRevenueEvents: RevenueEventRequestModel | null = null;
  isLoading = true;
  isWatchedRevenueEvent = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localRevenueEvents);
  }

  get revenueEventId(): string {
    return this.$route.params.revenueEventsId;
  }

  get revenueEvent() {
    return this.$store.state.revenueEventsStore.revenueEvent;
  }

  @Watch("applicationId", { immediate: true })
  async fetchRevenueEvents() {
    this.isLoading = true;
    await this.$store.dispatch(
      "loadRevenueEventById",
      new GetRevenueEventRequestModel(this.revenueEventId, this.applicationId)
    );
    this.isLoading = false;
  }

  @Watch("revenueEvent", { deep: true })
  private watchRevenueEvent(revenueEvent: RevenueEventModel) {
    this.localRevenueEvents = RevenueEventRequestModel.ofSource({
      applicationId: this.applicationId,
      ...cloneDeep(revenueEvent),
    });

    if (!this.isWatchedRevenueEvent) {
      this.isWatchedRevenueEvent = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.revenueEvents.editRevenueEventTitle")
    );
  }

  async handleSave(payload: RevenueEventRequestModel) {
    await this.$store.dispatch("updateRevenueEvent", payload);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.AD_REVENUE_EVENTS,
    });
  }
}
